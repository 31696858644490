import Head from 'next/head';
import Link from 'next/link';
import React from 'react';
import {
  Logo,
  IconBulb,
  IconGlyphBug,
  IconFilter,
  IconGlyphSquareEmptyQuestion,
  IconGlyphSquareInfo,
  Footer,
  // @ts-ignore
} from '@phpcreation/frontend-components-react';

const arrayNav = [
  {
    title: 'Client Feedback Form',
    link: '/feedback',
    desc: 'Access form where clients can create feedback tickets.',
    icon: <IconGlyphBug className="w-10 h-10" />,
  },
  {
    title: 'Anonymous Feedback Form',
    desc: 'Acess feedback-ticket form anonymously.',
    link: '/feedback/anonymous',
    icon: <IconGlyphSquareEmptyQuestion className="w-10 h-10" />,
  },
  {
    title: 'iframe Feedback Form',
    desc: 'Acess feedback-ticket form in an iframe.',
    link: '/feedback/iframe',
    icon: <IconGlyphSquareInfo className="w-10 h-10" />,
  },
  {
    title: 'Feedback Form with Login',
    desc: 'Access the feedback-ticket form with login',
    link: '/login',
    icon: <IconGlyphSquareInfo className="w-10 h-10" />,
  },
  {
    title: 'Back-end status',
    desc: 'Access status of the API Endpoints, generate default tokens.',
    link: '/status-api/view',
    icon: <IconFilter className="w-10 h-10 text-green-500" />,
  },
  {
    title: 'Documentation',
    desc: 'Dive into the full documentation from componenets, tech stack , tests ..',
    link: 'https://dev2.config.phpr.link/dev',
    icon: <IconBulb className="w-10 h-10 text-yellow-500" />,
  },
];

export default function Home() {
  return (
    <div
      className="flex flex-col min-h-screen bg-grayish"
      style={{ fontFamily: 'Roboto' }}
    >
      <Head>
        <title>PHPReaction | prototype</title>
        <meta name="description" content="phpreaction prototype" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div className="bg-white rounded-xl pt-2 px-2 scale-75  shadow m-2 w-40">
        <Logo />
      </div>
      <main className="z-10 flex  flex-col items-center  p-5 lg:p-24  ">
        <h2
          onClick={() => {
            console.log('width', window.innerWidth, 'px');
          }}
          className="desktop:text-3xl text-2xl  font-bold  text-gray-700  "
        >
          Welcome to PHPReaction 👋
        </h2>
        <p className="text-gray-500 desktop:text-base text-xs">
          Where would you like to go?
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 md:p-12 xl:gap-24">
          {arrayNav.map(item => (
            <Link key={arrayNav.indexOf(item)} href={item.link}>
              <div className="__Card flex flex-col  items-center text-center justify-between p-2 w-52 h-64 bg-white rounded-xl   border border-gray-300 shadow-sm hover:border-blue-400 cursor-pointer transform hover:scale-[1.02] transition-all ease-linear ">
                <div className="h-20  w-full flex items-center justify-center  ">
                  {item.icon}
                </div>
                <h2 className="desktop:text-base text-sm  capitalize">
                  {item.title}
                </h2>
                <p className="w-full  text-center text-gray-600 desktop:text-md  text-xs">
                  {item.desc}
                </p>
                <Link href={item.link}>
                  <a className="flex justify-center items-center  no-underline w-40  p-1 px-4 text-gray-500 desktop:text-base  text-xs">
                    Visit
                  </a>
                </Link>
              </div>
            </Link>
          ))}
        </div>
      </main>

      <Footer
        fqcn_bui={{
          Bundle: 'appBundle',
          Unit: 'main',
          Interface: 'footer',
        }}
      />
    </div>
  );
}
